<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <b-row align-v="end">
      <b-col class="exam-request">
        <validation-observer
          :ref="`${form.id}-form`"
          v-slot="{ invalid }"
          v-if="canEdit"
        >
          <b-row>
            <b-col :cols="treatmentCol">
              <b-form-group>
                <validation-provider
                  name="treatment"
                  rules="required"
                  v-slot="{ touched, errors }"
                >
                  <label for="treatment">Tipo de tratamento</label>
                  <multiselect
                    v-model="fields.tratamento"
                    id="treatment"
                    @select="getGlaucoPatientDiseaseByCpf"
                    :options="treatmentOptions"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="noResult">
                      Nenhum resultado encontrado
                    </template>
                  </multiselect>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="2" v-if="fields.tratamento === 'Injeção'">
              <b-form-group>
                <validation-provider
                  name="via"
                  rules="required"
                  v-slot="{ touched, errors }"
                >
                  <label for="via">Via</label>
                  <multiselect
                    v-model="fields.via"
                    id="via"
                    :options="viaOptions"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="noResult">
                      Nenhum resultado encontrado
                    </template>
                  </multiselect>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="4" v-if="fields.tratamento === 'Injeção'">
              <b-form-group>
                <validation-provider
                  name="via"
                  rules="required"
                  v-slot="{ touched, errors }"
                >
                  <label for="via">Fármaco</label>
                  <multiselect
                    v-model="fields.farmaco"
                    id="via"
                    label="label"
                    :options="farmacoOptions"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    class="search-mode"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <template slot="caret">
                      <div class="search">
                        <Search />
                      </div>
                    </template>
                    <template slot="noResult">
                      Nenhum resultado encontrado
                    </template>
                  </multiselect>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="2" v-if="fields.tratamento === 'Laserterapia'">
              <b-form-group>
                <validation-provider
                  name="via"
                  rules="required"
                  v-slot="{ touched, errors }"
                >
                  <label for="via">Tipo</label>
                  <multiselect
                    ref="tipo"
                    v-model="fields.tipo"
                    id="via"
                    :options="tipoOptions"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="noResult">
                      Nenhum resultado encontrado
                    </template>
                    <template slot="noResult" slot-scope="props">
                      <li @click="setCustom(props.search, 'tipo')">
                        <div class="multiselect__option custom-item">
                          <Plus class="icon" />
                          Adicionar tipo "{{ props.search }}"
                        </div>
                      </li>
                    </template>
                  </multiselect>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="4" v-if="fields.tratamento === 'Laserterapia'">
              <b-form-group>
                <validation-provider
                  name="via"
                  rules="required"
                  v-slot="{ touched, errors }"
                >
                  <label for="procedimento">Procedimento</label>
                  <multiselect
                    ref="procedimento"
                    id="procedimento"
                    v-model="fields.procedimento"
                    label="label"
                    :options="procedureOptions"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :internal-search="true"
                    placeholder="Buscar por procedimento"
                    class="search-mode"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <div class="text-truncate">
                        {{ props.option.label }}
                      </div>
                    </template>
                    <template slot="caret">
                      <div class="search">
                        <Search />
                      </div>
                    </template>
                    <template slot="noOptions">
                      Digite para pesquisar um procedimento
                    </template>
                    <template slot="noResult">
                      Nenhum procedimento encontrado
                    </template>
                  </multiselect>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="3" v-if="fields.tratamento">
              <b-form-group>
                <validation-provider
                  name="tratamentoPara"
                  rules="required"
                  v-slot="{ touched, errors }"
                >
                  <label for="tratamentoPara">Tratamento para</label>
                  <multiselect
                    ref="tratamentoPara"
                    v-model="fields.tratamentoPara"
                    id="tratamentoPara"
                    :options="tratamentoParaOptions"
                    :option-height="40"
                    :showLabels="false"
                    label="label"
                    :searchable="true"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="singleLabel" slot-scope="props">
                      <span
                        class="d-inline-block text-truncate w-100"
                        v-if="props.option.value"
                      >
                        {{ props.option.label }} (Glauco)
                        <img
                          src="@/assets/images/glauco_symbol.png"
                          class="logo-glauco"
                        />
                      </span>
                      <span class="d-inline-block text-truncate w-100" v-else>
                        {{ props.option.label }}
                      </span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <span v-if="props.option.value">
                        {{ props.option.label }} (Glauco)
                        <img
                          src="@/assets/images/glauco_symbol.png"
                          class="logo-glauco"
                        />
                      </span>
                      <span v-else>
                        {{ props.option.label }}
                      </span>
                    </template>
                    <template slot="noOptions">
                      Digite para pesquisar...
                    </template>
                    <template slot="noResult">
                      Nenhuma informação encontrada...
                    </template>
                    <template slot="noResult" slot-scope="props">
                      <li @click="setCustom(props.search, 'tratamentoPara')">
                        <div class="multiselect__option custom-item">
                          <Plus class="icon" />
                          Adicionar tratamento "{{ props.search }}"
                        </div>
                      </li>
                    </template>
                  </multiselect>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="fields.tratamento" class="align-items-center">
            <b-col cols="3">
              <b-form-group>
                <validation-provider
                  name="quantidadeSessoes"
                  rules="required"
                  v-slot="{ touched, errors }"
                >
                  <label for="quantidadeSessoes">Quantidade de sessões</label>
                  <multiselect
                    v-model="fields.quantidadeSessoes"
                    id="quantidadeSessoes"
                    :options="Array.from({ length: 10 }, (_, i) => i + 1)"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="noResult">
                      Nenhum resultado encontrado
                    </template>
                  </multiselect>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="5">
              <b-form-group>
                  <label for="intervaloSessoes">
                    Intervalo entre as sessões
                  </label>
                  <multiselect
                    v-model="fields.intervaloSessoes"
                    id="intervaloSessoes"
                    @search-change="changeOptionsIntervalo"
                    :options="intervaloOptions"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="noOptions">
                      Digite para pesquisar...
                    </template>
                    <template slot="noResult">
                      Nenhuma informação encontrada...
                    </template>
                  </multiselect>
              </b-form-group>
            </b-col>

            <b-col cols="2">
              <b-form-group>
                <validation-provider
                  name="lateralidade"
                  rules="required"
                  v-slot="{ touched, errors }"
                >
                  <label for="lateralidade">Lateralidade</label>
                  <multiselect
                    v-model="fields.lateralidade"
                    id="lateralidade"
                    :options="[
                      'Olho esquerdo',
                      'Olho direito',
                      'Ambos os olhos'
                    ]"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="noResult">
                      Nenhum resultado encontrado
                    </template>
                  </multiselect>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="2">
              <b-button
                variant="primary"
                block
                size="lg"
                class="mt-3"
                :disabled="invalid"
                @click="addItem"
              >
                Adicionar
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>

        <b-row>
          <b-col>
            <p class="table-title">Solicitações adicionadas</p>
            <hr />
            <div
              class="table-container"
              v-for="(value, index) of form.value"
              :key="index"
            >
              <div class="d-flex">
                <p>
                  <span class="header-text title">{{ value.tratamento }}</span>
                  |
                  <span class="header-text legend">
                    Tratamento para
                    {{ value.tratamentoPara?.label ?? value.tratamentoPara }}
                  </span>
                  <img
                    v-if="value.tratamentoPara.value"
                    src="@/assets/images/glauco_symbol.png"
                    class="logo-glauco"
                  />
                </p>

                <div class="ml-auto">
                  <label for="enable" class="bold-text">
                    <small class="status-treatment">
                      <small
                        class="circle"
                        :class="{ active: value?.active }"
                      />
                      {{ value?.active ? 'Em andamento' : 'Cessado' }}
                    </small>
                  </label>
                  <toggle-button
                    :disabled="!canEdit"
                    id="enable"
                    class="autoFillBtn mb-1"
                    :width="40"
                    :height="24"
                    @input="editActive($event, index)"
                    :value="value?.active"
                    :color="{ checked: '#00C773' }"
                  />
                </div>
              </div>
              <v-treatment-request-table
                :fields="getFields(value)"
                :indexTreatment="index"
                :items="value.sessoes"
                :canEdit="canEdit"
                :removeItem="removeItem"
                :editItem="editItem"
                :editTreatmentIndex="editTreatmentIndex"
                :editItemIndex="editItemIndex"
                :intervaloOptions="intervaloOptions"
                :viaOptions="viaOptions"
                :tipoOptions="tipoOptions"
                :procedureOptions="procedureOptions"
                :farmacoOptions="farmacoOptions"
                :changeOptionsIntervalo="changeOptionsIntervalo"
                :editValue="editValue"
                :getDefaultFields="getDefaultFields"
                @update-editing="isEditing = $event"
              />
              <div class="d-flex align-items-center justify-content-center">
                <b-button
                  variant="outline-primary"
                  size="lg"
                  class="add-button"
                  @click="addItemTable(index)"
                  :disabled="!canEdit || isEditing"
                >
                  <Plus class="icon" /> Adicionar
                  {{ value.tratamento === 'Laserterapia' ? 'Sessão' : 'Dose' }}
                </b-button>
              </div>

              <hr />

              <v-other-information
                ref="otherInformation"
                class="mb-3"
                :otherInformationsCreated="value.outrasInformacoes"
                :canEdit="canEdit"
                :addOutrasInformacoes="addOutrasInformacoes"
                :indexTreatment="index"
                :removeInformacao="removeInformacao"
                :newOtherInformation="otherInformation"
                @selected="selected = $event"
              />

              <div class="d-flex justify-content-end">
                <b-button
                  :disabled="!canEdit"
                  variant="link"
                  size="lg"
                  class="error-button"
                  @click="confirmRemoveTreatment(value, index)"
                >
                  <Trash class="icon trash" />Excluir tratamento
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <v-other-information-modal
      :otherInformation="selected"
      @setOtherInformation="setOtherInformation"
      @updateOtherInformation="updateOtherInformation"
    />
  </FormWrapper>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Plus from '@/assets/icons/plus.svg'
import Search from '@/assets/icons/search.svg'
import Trash from '@/assets/icons/trash.svg'
import TreatmentRequestTable from '@/components/Attendance/Forms/Components/TreatmentRequestTable'
import OtherInformation from '@/components/Attendance/Forms/Components/OtherInformation'
import OtherInformationModal from '@/components/Attendance/Forms/Components/OtherInformationModal'
import { getCurrentClinic } from '@/utils/localStorageManager'
import {
  INJECTION_DISEASES,
  LASER_DISEASES
} from '@/utils/treatmentRequestDiseases'

export default {
  components: {
    FormWrapper,
    ChevronDown,
    Search,
    Plus,
    Trash,
    'v-treatment-request-table': TreatmentRequestTable,
    'v-other-information': OtherInformation,
    'v-other-information-modal': OtherInformationModal
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.solicitacaoDeTratamento,
      patient: state => state.attendance.attendance?.patient
    }),
    treatmentCol() {
      return this.fields.tratamento ? 3 : 12
    }
  },
  async created() {
    await this.getGlaucoInjectionMedications()
    await this.getGlaucoLaserMedications()
  },
  data() {
    return {
      isEditing: false,
      clinic: getCurrentClinic(),
      editTreatmentIndex: null,
      editItemIndex: null,
      options: [],
      patientTreatment: {},
      treatmentOptions: ['Injeção', 'Laserterapia'],
      tipoOptions: [
        'Argônio',
        'CO2',
        'Diodo',
        'Excimer',
        'Femtosegundo',
        'Infravermelho',
        'Nd:YAG'
      ],
      viaOptions: [
        'Intravítrea',
        'Subconjuntival',
        'Subtenoniana',
        'Intracameral',
        'Peribulbar',
        'Retrobulbar',
        'Intralesional',
        'Perilesional',
        'Subcutânea',
        'Intramuscular'
      ],
      procedureOptions: [],
      farmacoOptions: [],
      tratamentoParaOptions: [],
      intervaloOptions: [],
      selected: {},
      otherInformation: {},
      fields: this.getDefaultFields()
    }
  },
  methods: {
    ...mapActions('attendance/form', [
      'updateMedicalRecord',
      'destroyMedicalRecord'
    ]),
    ...mapActions('attendance/form/solicitacaoDeTratamento', ['handleProps']),
    async getGlaucoPatientDiseaseByCpf() {
      try {
        this.tratamentoParaOptions = []
        if (this.patient?.cpf) {
          const { data } = await this.api.getGlaucoPatientDiseaseByCpf(
            this.patient.cpf
          )
          this.tratamentoParaOptions = data.userTreatments.map(treatment => {
            return {
              value: treatment.id,
              label: treatment.disease
            }
          })

          this.fields.patientGlaucoId = data?.user?.id
        }
      } catch (error) {
        this.$toast.error(error.message)
      }
      if (this.fields.tratamento === 'Injeção') {
        this.fillInjectionDiseases()
      }
      if (this.fields.tratamento === 'Laserterapia') {
        this.fillLaserDiseases()
      }
    },
    fillLaserDiseases() {
      const toFill = LASER_DISEASES.filter(
        disease => !this.tratamentoParaOptions.includes(disease)
      )
      this.tratamentoParaOptions.push(...toFill)
    },
    fillInjectionDiseases() {
      const toFill = INJECTION_DISEASES.filter(
        disease => !this.tratamentoParaOptions.includes(disease)
      )
      this.tratamentoParaOptions.push(...toFill)
    },

    async getGlaucoLaserMedications() {
      try {
        const { data } = await this.api.getGlaucoLaserMedications()

        this.procedureOptions = data.map(medication => {
          return {
            value: medication.id,
            label: medication.medicine
          }
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async getGlaucoInjectionMedications() {
      try {
        const { data } = await this.api.getGlaucoInjectionMedications()
        this.farmacoOptions = data.map(medication => {
          return {
            value: medication.id,
            label: medication.medicine
          }
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    getDefaultFields() {
      return {
        tratamento: null,
        tipo: '',
        procedimento: null,
        tratamentoPara: null,
        quantidadeSessoes: null,
        intervaloSessoes: null,
        lateralidade: null,

        via: null,
        farmaco: null,
        custom: null,
        active: true,
        patientGlaucoId: null
      }
    },
    addItem() {
      const value = [...this.form.value]

      const sessoes = []
      for (let i = 0; i < this.fields.quantidadeSessoes; i++) {
        let intervalo = 'Início do tratamento'
        if (
          i > 0 &&
          this.fields.intervaloSessoes !== 'Personalizado (Treat and extend)'
        ) {
          intervalo = `${this.fields.intervaloSessoes} após a dose ${i}`
        } else if (
          i !== 0 &&
          this.fields.intervaloSessoes === 'Personalizado (Treat and extend)'
        ) {
          intervalo = ''
        }

        let sessao = {
          sessao: i + 1,
          lateralidade: this.fields.lateralidade,
          intervalo,
          situacao: 'Pendente'
        }
        if (this.fields.tratamento === 'Laserterapia') {
          sessao = {
            ...sessao,
            tratamento: 'Laserterapia',
            tipo: this.fields.tipo,
            procedimento: this.fields.procedimento,
            tratamentoPara: this.fields.tratamentoPara
          }
        } else {
          sessao = {
            ...sessao,
            tratamento: 'Injeção',
            via: this.fields.via,
            farmaco: this.fields.farmaco,
            tratamentoPara: this.fields.tratamentoPara
          }
        }
        sessoes.push(sessao)
      }

      this.fields.active = true
      this.fields.sessoes = sessoes
      this.fields.date = this.moment()

      value.push(this.fields)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
      this.fields = this.getDefaultFields()
    },
    addItemTable(index) {
      const value = [...this.form.value]
      this.isEditing = true

      value[index].sessoes.push({
        ...this.getDefaultFields(),
        sessao: value[index].sessoes.length + 1,
        intervalo: `${value[index].intervaloSessoes} após a dose ${value[index].sessoes.length}`,
        situacao: 'Pendente'
      })
      this.editTreatmentIndex = index
      this.editItemIndex = value[index].sessoes.length - 1

      this.handleProps({ key: 'value', value })
    },
    async addOutrasInformacoes(index, informacao, editingIndex = null) {
      const value = [...this.form.value]

      if (editingIndex !== null) {
        value[index].outrasInformacoes.splice(this.editingIndex, 1)
      }

      if (!value[index].outrasInformacoes) {
        value[index].outrasInformacoes = []
      }
      value[index].outrasInformacoes.push(informacao)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    changeOptionsIntervalo(query) {
      const options = []
      const treatedText = query.replace(/\D/g, '')
      if (parseInt(treatedText)) {
        if (treatedText === '1') {
          options.push(
            `${treatedText} dia`,
            `${treatedText} semana`,
            `${treatedText} mês`,
            `${treatedText} ano`
          )
        } else {
          options.push(
            `${treatedText} dias`,
            `${treatedText} semanas`,
            `${treatedText} mêses`,
            `${treatedText} anos`
          )
        }
      } else {
        options.push(`1 dia`, `1 semana`, `1 mês`, `1 ano`)
      }
      options.push('Personalizado (Treat and extend)')
      this.intervaloOptions = options
    },
    setCustom(value, key) {
      this.fields.custom = value

      const ref = this.$refs[key]
      setTimeout(() => {
        ref.$refs.search.blur()
      }, 500)
      if (key === 'tipo') {
        this.fields[key] = value
        return
      }
      this.fields[key] = {
        label: value,
        value: null
      }
    },
    editItem(index, secondIndex) {
      const value = [...this.form.value]
      const findIndex = value[index].sessoes.findIndex(
        sessao => sessao.sessao === secondIndex + 1
      )
      this.editTreatmentIndex = index
      this.editItemIndex = findIndex
      value[index].editing = true
      this.handleProps({ key: 'value', value })
    },
    editActive($event, index) {
      const value = [...this.form.value]
      value[index].active = $event

      this.updateMedicalRecord(this.form)
      this.handleProps({ key: 'value', value })
    },
    removeItem(index, secondIndex) {
      const value = [...this.form.value]
      value[index].sessoes.splice(secondIndex, 1)
      this.isEditing = false
      this.editItemIndex = null

      if (!value[index].sessoes.length) {
        value.splice(index, 1)
      }
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    editValue(index, secondIndex, fields) {
      const value = [...this.form.value]
      this.$set(value[index].sessoes, secondIndex, {
        ...value[index].sessoes[secondIndex],
        ...fields
      })

      this.editTreatmentIndex = null
      this.editItemIndex = null

      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    removeInformacao(index, secondIndex) {
      const value = [...this.form.value]
      value[index].outrasInformacoes.splice(secondIndex, 1)

      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    confirmRemoveTreatment(treatment, index) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">
            Você tem certeza que deseja excluir o tratamento
            <span>${treatment.tratamento} | Tratamento para ${
          treatment.tratamentoPara?.label ?? treatment.tratamentoPara
        }</span>
            do paciente <span>${this.patient.name}</span>?
            <p>Não será possível desfazer essa ação.</p>
            </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(res => {
        if (res.isConfirmed) {
          this.removeTreatment(index)
        }
      })
    },
    removeTreatment(index) {
      const value = [...this.form.value]
      value.splice(index, 1)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    getFields(treatment) {
      return treatment.tratamento === 'Laserterapia'
        ? [
            'Sessão',
            'Tipo',
            'Procedimento',
            'Lateral.',
            'Intervalos',
            'Situação'
          ]
        : ['Dose', 'Via', 'Fármaco', 'Lateral.', 'Intervalos', 'Situação']
    },
    setOtherInformation(value) {
      this.otherInformation = {
        label: value.name,
        value: value.id,
        template: value.template
      }
    },
    updateOtherInformation(value) {
      this.otherInformation = {
        label: value.name,
        value: value.id,
        template: value.template,
        updated: true
      }
    },
    hideForm() {
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.value.forEach((key, index) => {
        this.removeItem(index)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.exam-request {
  display: flex !important;
  flex-direction: column !important;
  .multiselect {
    border: 1px solid #c6ceeb;
    border-radius: 8px;
  }
}

.icon {
  width: 24px;
  height: 24px;
  fill: var(--blue-500);
  margin-right: 12px;

  &.trash {
    stroke: var(--states-red-soft) !important;
    fill: none !important;
    width: 20px !important;
    height: 20px !important;
  }
}

.multiselect {
  .custom-item {
    color: var(--blue-500);
    display: flex;
    align-items: center;
    font-weight: 600;
  }
  .multiselect__single {
    @media (min-height: 1600) {
      font-size: 1.6vh;
    }
    @media (max-height: 1600) {
      font-size: 1vw;
    }
  }
}

.table-title {
  font-weight: 700;
  font-size: 14px;
  color: var(--type-active);
}

.table-container {
  background-color: var(--neutral-000);
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 2%;
  .header-text {
    font-weight: 600;
    font-size: 14px;
    color: var(--type-active);

    &.title {
      color: var(--type-active);
    }

    &.legend {
      color: var(--type-placeholder);
    }
  }

  .status-treatment {
    font-weight: 600;
    font-size: 12px;
    color: var(--type-placeholder);

    .circle {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
      background-color: var(--orange);

      &.active {
        background-color: var(--states-success) !important;
      }
    }
  }

  .add-button {
    font-weight: 700;
    font-size: min(1.2vw, 16px);
    color: var(--blue-500);
  }

  .error-button {
    color: var(--states-error);
    font-weight: 600;
    font-size: min(1vw, 14px);
  }
}
</style>
