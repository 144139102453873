<template>
  <b-table-simple responsive class="table-eyecare">
    <thead>
      <tr>
        <th class="text-center" v-for="(field, index) in fields" :key="index">
          {{ field }}
        </th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, key) in items"
        :key="key"
        :class="{ 'edit-tr': edit(key) }"
      >
        <td>{{ item.sessao }}</td>
        <td class="text-center">
          <span v-if="!edit(key)">
            {{ item.tratamento === 'Laserterapia' ? item.tipo : item.via }}
          </span>
          <multiselect
            v-else-if="item.tratamento === 'Laserterapia'"
            :ref="`tipo-${editItemIndex}`"
            v-model="form.tipo"
            :options="tipoOptions"
            :option-height="40"
            @select="selectOption($event, 'tipo', key)"
            :showLabels="false"
            :searchable="true"
            :allowEmpty="false"
            placeholder="Selecionar"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noResult" slot-scope="props">
              <li @click="setCustom(props.search, 'tipo')">
                <div class="multiselect__option custom-item">
                  <Plus class="icon" />
                  Adicionar tipo "{{ props.search }}"
                </div>
              </li>
            </template>
          </multiselect>
          <multiselect
            v-else
            v-model="form.via"
            :options="viaOptions"
            :option-height="40"
            @select="selectOption($event, 'via', key)"
            :showLabels="false"
            :searchable="true"
            :allowEmpty="false"
            placeholder="Selecionar"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noResult"> Nenhum resultado encontrado </template>
          </multiselect>
        </td>

        <td class="text-center">
          <span v-if="!edit(key)">
            {{
              item.tratamento === 'Laserterapia'
                ? item.procedimento?.label
                : item.farmaco?.label
            }}
          </span>
          <multiselect
            v-else-if="item.tratamento === 'Laserterapia'"
            :ref="`procedimento-${editItemIndex}`"
            v-model="form.procedimento"
            :options="procedureOptions"
            :option-height="40"
            label="label"
            @select="selectOption($event, 'procedimento', key)"
            :showLabels="false"
            :searchable="true"
            :allowEmpty="false"
            placeholder="Selecionar"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noResult">
              Nenhum procedimento encontrado
            </template>
          </multiselect>
          <multiselect
            v-else
            v-model="form.farmaco"
            :options="farmacoOptions"
            :option-height="40"
            label="label"
            @select="selectOption($event, 'farmaco', key)"
            :showLabels="false"
            :searchable="true"
            :allowEmpty="false"
            placeholder="Selecionar"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noResult"> Nenhum resultado encontrado </template>
          </multiselect>
        </td>

        <td class="text-center">
          <span v-if="!edit(key)">
            {{ parseLaterality(item.lateralidade) }}
          </span>
          <multiselect
            v-else
            v-model="form.lateralidade"
            :options="['Olho esquerdo', 'Olho direito', 'Ambos os olhos']"
            :option-height="40"
            @select="selectOption($event, 'lateralidade', key)"
            :showLabels="false"
            :searchable="true"
            :allowEmpty="false"
            placeholder="Selecionar"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noResult">Nenhum resultado encontrado</template>
          </multiselect>
        </td>

        <td class="text-center">
          <span v-if="key === 0 || !canEdit">{{ item.intervalo }}</span>
          <multiselect
            v-else
            v-model="item.intervalo"
            @search-change="changeOptionsIntervalo"
            :options="computedIntervalOptions"
            @select="selectOption($event, 'intervalo', key)"
            :option-height="40"
            :showLabels="false"
            :searchable="true"
            :allowEmpty="false"
            class="with-border"
            placeholder="Selecionar"
            :disabled="editItemIndex !== key && editItemIndex !== null"
          >
            <template slot="noOptions">Digite para pesquisar...</template>
            <template slot="noResult">
              Nenhuma informação encontrada...
            </template>
          </multiselect>
        </td>

        <td class="text-center">
          <span
            class="situation"
            v-if="!edit(key)"
            :class="{ realized: item.situacao === 'Realizada' }"
          >
            {{ item.situacao }}
          </span>

          <multiselect
            v-else
            v-model="form.situacao"
            :options="['Pendente', 'Realizada']"
            @select="selectOption($event, 'situacao', key)"
            :option-height="40"
            :showLabels="false"
            :searchable="true"
            :allowEmpty="false"
            placeholder="Selecionar"
          >
            <template slot="singleLabel" slot-scope="props">
              <span
                class="situation"
                :class="{ realized: props.option === 'Realizada' }"
              >
                {{ props.option }}
              </span>
            </template>
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noResult">
              Nenhuma informação encontrada...
            </template>
          </multiselect>
        </td>

        <td class="text-right">
          <div class="more-with-menu">
            <MoreVertical class="more-icon" />
            <div class="menu">
              <b-button
                variant="link"
                @click="editItemMethod(key)"
                :disabled="!canEdit || Boolean(editItemIndex)"
              >
                Editar
              </b-button>
              <b-button
                variant="link remove mb-0"
                @click="removeItemMethod(key)"
                :disabled="!canEdit || Boolean(editItemIndex && editItemIndex !== key)"
              >
                Remover
              </b-button>
            </div>
          </div>

          <div>
            <b-button
              class="edit-buttom"
              variant="primary"
              :disabled="!canEdit || formInvalid(item)"
              @click="editValueMethod(key, form)"
              v-if="edit(key)"
            >
              Salvar alterações
            </b-button>
          </div>
        </td>
      </tr>
    </tbody>
  </b-table-simple>
</template>

<script>
import MoreVertical from '@/assets/icons/more-vertical.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Plus from '@/assets/icons/plus.svg'
import { parseLaterality } from '@/utils/eyesHelper'

export default {
  name: 'TreatmentRequestTable',
  components: {
    MoreVertical,
    ChevronDown,
    Plus
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    removeItem: {
      type: Function,
      required: true
    },
    editItem: {
      type: Function,
      required: true
    },
    indexTreatment: {
      type: Number,
      required: true
    },
    editItemIndex: Number,
    editTreatmentIndex: Number,
    intervaloOptions: {
      type: Array,
      default: () => []
    },
    viaOptions: {
      type: Array,
      default: () => []
    },
    tipoOptions: {
      type: Array,
      default: () => []
    },
    procedureOptions: {
      type: Array,
      default: () => []
    },
    farmacoOptions: {
      type: Array,
      default: () => []
    },
    editValue: {
      type: Function,
      required: true
    },
    changeOptionsIntervalo: {
      type: Function,
      required: true
    },
    getDefaultFields: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      form: this.getDefaultFields()
    }
  },
  computed: {
    computedIntervalOptions() {
      const index = this.items.findIndex(
        item => item === 'Personalizado (Treat and extend)'
      )

      if (index > -1) {
        return this.intervaloOptions
      }
      const newArray = [...this.intervaloOptions]
      newArray.splice(index, 1)
      return newArray
    }
  },
  methods: {
    formInvalid(item) {
      const {
        tipo,
        intervalo,
        via,
        farmaco,
        procedimento,
        lateralidade,
        situacao
      } = item
      const isInvalid = !intervalo || !lateralidade || !situacao
      if (item?.tratamento === 'Laserterapia') {
        return isInvalid || !tipo || !procedimento
      }
      return isInvalid || !via || !via || !farmaco
    },
    setCustom(value, key) {
      this.form.custom = value

      const ref = this.$refs[`${key}-${this.editItemIndex}`]
      setTimeout(() => {
        ref[0].$refs.search.blur()
      }, 500)

      if (key === 'tipo') {
        this.form[key] = value
        return
      }
      this.form[key] = {
        label: value,
        value: null
      }
    },
    edit(index) {
      return (
        this.indexTreatment === this.editTreatmentIndex &&
        this.editItemIndex === index
      )
    },
    removeItemMethod(index) {
      this.removeItem(this.indexTreatment, index)
      this.$emit('update-editing', false)
      this.form = this.getDefaultFields()
    },
    editItemMethod(index) {
      this.editItem(this.indexTreatment, index)
      this.$emit('update-editing', true)
      this.form = { ...this.items[index] }
    },
    editValueMethod(index, form) {
      this.editValue(this.indexTreatment, index, form)
      this.$emit('update-editing', false)
      this.form = this.getDefaultFields()
    },
    selectOption(value, field, index) {
      this.$set(this.items, index, { ...this.items[index], [field]: value })

      if (field === 'intervalo' && index) {
        this.form[field] = `${value} após a dose ${index}`
        if (this.editItemIndex === null) {
          this.editValue(this.indexTreatment, index, {
            ...this.items[index],
            intervalo: this.form[field]
          })
          this.form = this.getDefaultFields()
        }
      }
    },
    parseLaterality
  }
}
</script>

<style lang="scss" scoped>
.table-eyecare {
  padding: 16px;

  .edit-tr {
    height: 110px;
  }

  .situation {
    width: fit-content;
    padding: 10px;
    background-color: var(--light-orange-200);
    border: none;
    border-radius: 16px;

    color: var(--type-active);
    font-weight: 600;
    font-size: 12px;

    &.realized {
      background-color: var(--states-success-soft-green) !important;
    }
  }

  .icon {
    width: 20px;
    height: 20px;
    fill: var(--blue-500);
    margin-right: 12px;
  }

  .edit-buttom {
    position: absolute;
    width: 15%;
    margin-left: -12% !important;
    margin-top: 1.5%;
  }

  .multiselect {
    margin-left: auto !important;
    width: 80% !important;
    :deep(.multiselect__single) {
      font-size: 12px !important;
    }

    :deep(.multiselect__input) {
      font-size: 12px !important;
    }

    :deep(.multiselect__option) {
      font-size: 12px !important;
    }

    .custom-item {
      color: var(--blue-500);
      display: flex;
      align-items: center;
      font-weight: 600;
    }

    &.multiselect--disabled {
      :deep(.multiselect__select) {
        background-color: var(--neutral-000) !important;
      }
    }

  }
}
</style>
