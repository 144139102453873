<template>
  <b-modal
    id="other-information-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @hidden="closeModal"
  >
    <div class="modal-header">
      <div class="modal-title" v-if="otherInformation?.value">
        Editar modelo de informação personalizado
      </div>
      <div class="modal-title" v-else>Criar modelo de outras informações</div>
      <span class="icon-box">
        <v-close class="icon stroke" @click="closeModal" />
      </span>
    </div>
    <div class="around-content">
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label for="name">Título do da informação</label>
            <b-form-input
              type="text"
              autocomplete="off"
              id="name"
              :class="{ error: validate && !name }"
              v-model="name"
            />
            <div v-if="validate && !name" class="custom-invalid-feedback">
              Campo obrigatório
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <div class="form-group">
            <label for="descricao_informacao">Descrição da informação</label>
            <TextArea
              id="descricao_informacao"
              :value="descricao"
              placeholder="Descrever"
              @input="value => (descricao = value)"
            />
          </div>
        </b-col>
      </b-row>

      <div class="wrapper-button">
        <div class="d-flex" v-if="otherInformation?.value">
          <b-button
            variant="outline-primary"
            class="mr-2"
            size="lg"
            @click="closeModal"
          >
            Cancelar
          </b-button>
          <b-button variant="primary" size="lg" @click="updateOtherInformation">
            Salvar alterações
          </b-button>
        </div>
        <b-button
          variant="primary"
          size="lg"
          v-else
          @click="saveOtherInformation"
        >
          Adicionar
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Close from '@/assets/icons/close.svg'
import TextArea from '@/components/General/TextArea'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'OtherInformationModal',
  props: {
    report: String,
    otherInformation: Object
  },
  data: () => ({
    clinic: getCurrentClinic(),
    name: null,
    descricao: null,
    validate: false
  }),
  components: {
    'v-close': Close,
    TextArea
  },
  methods: {
    closeModal() {
      this.setDefaultFields()
      this.$bvModal.hide('other-information-modal')
    },
    isValidForm() {
      this.validate = true
      return Boolean(this.name && this.descricao)
    },
    clearModal() {
      this.name = null
      this.descricao = null
      this.validate = false
    },
    async saveOtherInformation() {
      if (!this.isValidForm()) return
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.createOtherInformation({
          clinic_id: this.clinic.id,
          name: this.name,
          template: this.descricao
        })
        this.clearModal()
        this.$toast.success('Modelo de informação salvo com sucesso!')
        this.$emit('setOtherInformation', data)
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async updateOtherInformation() {
      if (!this.isValidForm()) return
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.updateOtherInformation(
          this.otherInformation.value,
          {
            name: this.name,
            template: this.descricao
          }
        )
        this.$toast.success('Alterações no name salvas com sucesso!')
        this.$emit('updateOtherInformation', data)
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    setDefaultFields() {
      this.name = null
      this.descricao = null
    }
  },
  watch: {
    report(value) {
      if (value) {
        this.name = value
      }
    },
    otherInformation: {
      handler(value) {
        if (value?.value) {
          this.name = value.label
          this.descricao = value.template
        } else {
          this.setDefaultFields()
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
#other-information-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
  }

  .around-content {
    padding: 24px 24px 0 24px;
    textarea {
      min-height: 200px;
      max-height: 1000px;
    }
  }

  .wrapper-button {
    width: 100%;
    display: flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
