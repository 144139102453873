<template>
  <b-container fluid>
    <validation-observer
      ref="otherInformationObserver"
      v-slot="{ invalid }"
      v-if="canEdit"
    >
      <b-row>
        <b-col cols="12">
          <div class="position-relative" v-if="canEdit">
            <b-form-group>
              <validation-provider name="other-information" rules="required">
                <label for="other-information">
                  Outras informações <span class="help">(opcional)</span>
                </label>
                <multiselect
                  id="other-information"
                  :value="selected"
                  :options="otherInformations"
                  :option-height="40"
                  :showLabels="false"
                  :searchable="true"
                  label="label"
                  :internal-search="false"
                  @select="selectOtherInformation"
                  @remove="clearFields"
                  placeholder="Selecionar"
                  class="with-border"
                >
                  <template slot="noResult">
                    Nenhuma informação encontrada
                  </template>
                  <template slot="noOptions">
                    Nenhuma resultado encontrada
                  </template>
                </multiselect>
                <div class="new-other-information">
                  <Icon tooltip="Limpa seleção" v-if="form.template">
                    <Close class="icon close" @click="clearFields" />
                  </Icon>
                  <Icon
                    tooltip="Criar novo modelo de observação"
                    v-if="showAddCustom"
                  >
                    <Plus
                      class="icon"
                      @click="$bvModal.show('other-information-modal')"
                    />
                  </Icon>
                  <Icon tooltip="Editar" v-if="!showAddCustom">
                    <Edit
                      class="icon edit"
                      @click="$bvModal.show('other-information-modal')"
                    />
                  </Icon>
                  <Icon tooltip="Deletar" v-if="!showAddCustom">
                    <Trash class="icon trash" @click="confirmDelete" />
                  </Icon>
                </div>
              </validation-provider>
            </b-form-group>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group>
            <validation-provider name="outras-informacoes" rules="required">
              <label for="outras-informacoes">Descrição</label>
              <TextArea
                id="outras-informacoes"
                :value="form.template"
                :disabled="!canEdit"
                placeholder="Descrever"
                @input="value => (form.template = value)"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-end">
        <b-button
          variant="primary"
          class="mt-27 h-56"
          :disabled="invalid"
          @click="addOutraInformacaoMethod(indexTreatment, form)"
        >
          {{ editingIndex !== null ? 'Atualizar' : 'Adicionar' }}
        </b-button>
      </div>
    </validation-observer>

    <b-row>
      <b-col>
        <table class="table-eyecare">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Observação</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, index) of otherInformationsCreated" :key="index">
              <td class="multiple-lines">{{ value.name }}</td>
              <td>
                <perfect-scrollbar :options="{ suppressScrollX: true }">
                  <div class="template">
                    <span class="pre-formatted" v-html="value.template" />
                  </div>
                </perfect-scrollbar>
              </td>
              <td class="text-right">
                <div class="more-with-menu" v-if="canEdit">
                  <MoreVertical class="more-icon" />
                  <div class="menu">
                    <b-button
                      variant="link"
                      @click="editItem(value, index, editingIndex)"
                    >
                      Editar
                    </b-button>
                    <b-button
                      variant="link remove mb-0"
                      @click="removeInformacao(indexTreatment, index)"
                    >
                      Remover
                    </b-button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { getCurrentUser } from '@/utils/localStorageManager'
import Icon from '@/components/General/Icon'
import Trash from '@/assets/icons/trash.svg'
import Plus from '@/assets/icons/plus.svg'
import Close from '@/assets/icons/close.svg'
import Edit from '@/assets/icons/edit.svg'
import MoreVertical from '@/assets/icons/more-vertical.svg'
import TextArea from '@/components/General/TextArea'

export default {
  name: 'OtherInformation',
  props: {
    canEdit: {
      type: Boolean,
      default: true
    },
    otherInformationsCreated: {
      type: Array,
      default: () => []
    },
    indexTreatment: {
      type: Number,
      required: true
    },
    addOutrasInformacoes: {
      type: Function,
      required: true
    },
    removeInformacao: {
      type: Function,
      required: true
    },
    newOtherInformation: {
      type: Object,
      default: () => ({})
    }
  },
  async created() {
    await this.getOtherInformations()
  },
  components: {
    TextArea,
    Icon,
    Trash,
    Plus,
    Close,
    Edit,
    MoreVertical
  },
  data: () => ({
    professional: getCurrentUser(),
    otherInformations: [],
    form: {
      name: null,
      template: null
    },
    editingIndex: null
  }),
  computed: {
    showAddCustom() {
      return Boolean(!this.selected?.value)
    },
    selected() {
      return this.otherInformations.find(item => item.label === this.form.name)
    }
  },
  methods: {
    async getOtherInformations() {
      try {
        const { data } = await this.api.getOtherInformations(
          this.professional.id
        )
        this.otherInformations = data.map(item => {
          return {
            label: item.name,
            value: item.id,
            template: item.template
          }
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async confirmDelete() {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">
            Você tem certeza que deseja excluir a observação
            <span>${this.selected.label}</span>
            <p>Não será possível desfazer essa ação.</p>
            </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(async res => {
        if (res.isConfirmed) {
          await this.deleteInformation()
        }
      })
    },
    async deleteInformation() {
      try {
        await this.api.deleteOtherInformation(this.selected.value)
        this.$toast.success('Informação excluída com sucesso')
        this.removeOption(this.selected.value)
        this.clearFields()
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    removeOption(id) {
      const index = this.otherInformations.findIndex(item => item.value === id)
      this.otherInformations.splice(index, 1)
    },
    selectOtherInformation(option) {
      this.form.name = option.label
      this.form.template = option.template
    },
    clearFields() {
      this.form.name = null
      this.form.template = null
    },
    editItem(value, index) {
      this.form = { ...value }
      this.editingIndex = index
    },
    async addOutraInformacaoMethod() {
      const value = { ...this.form }
      await this.addOutrasInformacoes(
        this.indexTreatment,
        value,
        this.editingIndex
      )
      this.editingIndex = null
      this.clearFields()
    },
    updateOtherInformation() {
      const index = this.otherInformations.findIndex(
        item => item.value === this.newOtherInformation.value
      )
      this.$set(this.otherInformations, index, {
        label: this.newOtherInformation.label,
        value: this.newOtherInformation.value,
        template: this.newOtherInformation.template
      })

      this.form = {
        name: this.newOtherInformation.label,
        template: this.newOtherInformation.template
      }
    }
  },
  watch: {
    selected(value) {
      this.$emit('selected', value)
    },
    newOtherInformation: {
      handler(value) {
        if (value?.updated) {
          this.updateOtherInformation()
        } else if (value?.label) {
          this.otherInformations.push(value)
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
textarea {
  min-height: 200px;
  max-height: 1000px;
}
.new-other-information {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: end;
  height: 58px;
  top: 0;
  right: 10px;
  z-index: 10;
  gap: 10px;

  .icon {
    width: 20px;
    height: 20px;
    fill: var(--blue-500);
    cursor: pointer;

    &.trash {
      fill: none;
      stroke: var(--states-red-soft);
    }

    &.edit {
      fill: none;
      stroke: var(--blue-500);
    }

    &.close {
      fill: var(--type-active);
    }
  }
}
.multiselect {
  .custom-item {
    color: var(--blue-500);
    display: flex;
    align-items: center;
    font-weight: 600;
    .icon {
      width: 24px;
      height: 24px;
      fill: var(--blue-500);
      margin-right: 12px;
    }
  }
  .multiselect__single {
    @media (min-height: 1600) {
      font-size: 1.6vh;
    }
    @media (max-height: 1600) {
      font-size: 1vw;
    }
  }
}

.template {
  max-height: 200px !important;
  .pre-formatted {
    word-break: break-word;
    white-space: pre-wrap;
    -moz-white-space: pre-wrap;
  }
}
</style>
