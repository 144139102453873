export const INJECTION_DISEASES = [
  { label: 'Ambliopia', value: null },
  { label: 'Astigmatismo', value: null },
  { label: 'Blefarite', value: null },
  { label: 'Degeneração macular relacionada à idade', value: null },
  { label: 'Edema macular diabético', value: null },
  { label: 'Endoftalmite', value: null },
  { label: 'Membrana neovascular', value: null },
  { label: 'Oclusão venosa', value: null },
  { label: 'Retinopatia da prematuridade', value: null },
  { label: 'Retinopatia diabética proliferativa', value: null },
  { label: 'Uveíte', value: null }
]

export const LASER_DISEASES = [
  { label: 'Ambliopia', value: null },
  { label: 'Astigmatismo', value: null },
  { label: 'Blefarite', value: null },
  { label: 'Coroidopatia serosa central', value: null },
  { label: 'Descolamento de retina', value: null },
  { label: 'Edema macular cistóide', value: null },
  { label: 'Glaucoma de ângulo aberto', value: null },
  { label: 'Glaucoma de ângulo fechado', value: null },
  { label: 'Glaucoma refratário', value: null },
  { label: 'Hipermetropia', value: null },
  { label: 'Miopia', value: null },
  { label: 'Olho seco', value: null },
  { label: 'Opacidade de cápsula posterior', value: null },
  { label: 'Retinopatia da prematuridade', value: null },
  { label: 'Retinopatia diabética', value: null }
]
